export const companies = [
    {
        name: "Huawei Canada",
        jobTitle: "Software Engineer",
        setting: "Markham, September 2019 - December 2019",
        description: 
            <div className='description'>
                I was part of the <span className="highlight">Distributed Data</span> and <span className="highlight">Artificial Intelligence</span> team at Huawei Canada, working on the MindSpore AI computing framework.
            </div>,
        rolesText: "My roles as a Software Engineer:",
        roles: 
            <div className='roles'>
                <ul className='roleList'>
                    <li>Developed and assessed a new data format for MindSpore</li>
                    <li>Actively participated in the design process for both internal and customer-facing APIs of MindSpore</li>
                    <li>Substantially incorporated <span className="highlight">Object-Oriented Design</span> and <span className="highlight">Multithreading</span> in C++ to maximize code readability and efficiency</li>
                </ul>
            </div>
    },
    {
        name: "Thomson Reuters",
        jobTitle: "Software Developer",
        setting: "Toronto, May 2020 - August 2020",
        description: 
            <div className='description'>
                I was part of the team working on Legal Tracker which is the world's most widely used legal e-billing, matter management, and legal operations software.
            </div>,
        rolesText: "My roles as a Software Developer:",
        roles: 
            <div className='roles'>
                <ul className='roleList'>
                    <li>Engaged in the full-stack development of Legal Tracker in the <span className="highlight">C# MVC</span> framework</li>
                    <li>Efficiently adapted to work on various modules such as RESTful APIs, UI/UX, and testing</li>
                    <li>Investigated and patched multiple bugs, while ensuring product quality through regression tests</li>
                    <li>Worked in an <span className="highlight">Agile</span> environment following strict deadlines for new features and requirements</li>
                </ul>
            </div>
    },
    {
        name: "Untether AI",
        jobTitle: "AI Accelerator Engineer",
        setting: "Toronto, January 2021 - April 2021",
        description: 
            <div className='description'>
                Untether AI is a startup based in Toronto, Canada producing high-performance AI inference chips.
            </div>,
        rolesText: "My roles as an AI Accelerator Engineer:",
        roles: 
            <div className='roles'>
                <ul className='roleList'>
                    <li>Built and expanded software components in a full-stack manner from high-level Python to <span className="highlight">low-level</span> custom instruction sets for a proprietary AI Inference chip</li>
                    <li>Applied efficient implementations for neural network kernels under specialized hardware constraints</li>
                    <li>Restructured the testing framework, allowing for more accurate throughput calculations for kernels and <span className="highlight">neural networks</span> running on simulated hardware</li>
                </ul>
            </div>
    },
    {
        name: "Wish",
        jobTitle: "Software Engineer",
        setting: "Toronto, September 2021 - December 2021",
        description: 
            <div className='description'>
                I was part of the <span className="highlight">Data and Relevancy</span> Team at Wish.
            </div>,
        rolesText: "My roles as a Software Engineer:",
        roles: 
            <div className='roles'>
                <ul className='roleList'>
                    <li>Built and tested <span className="highlight">Image Embedding ML models</span> for Wish’s Related Products Feed</li>
                    <li>Analyzed both offline and live statistics from <span className="highlight">A/B testing</span> to determine the most profitable models</li>
                    <li>Researched and developed proprietary neural networks to further improve modeling</li>
                    <li>Ensured durable pipelines through rigorous use and testing of <span className="highlight">Docker</span> environments and cron jobs</li>
                </ul>
            </div>
    },
    {
        name: "Aven",
        jobTitle: "Software Engineer",
        setting: "Burlingame, May 2022 - August 2022",
        description: 
            <div className='description'>
                Aven is a startup based in Burlingame, California providing credit cards backed by your home equity.
            </div>,
        rolesText: "My roles as a Software Engineer:",
        roles: 
            <div className='roles'>
                <ul className='roleList'>
                    <li>Directed end-to-end development of various features in a <span className="highlight">fast-paced</span> startup environment</li>
                    <li>Restructured and accelerated Aven’s entire compliance system to fulfill complex legal requirements. Brought HMDA compliance from 10% to 90% production-ready</li>
                    <li>Persistently resolved numerous customer and code incidents caused by lingering and novel system issues</li>
                </ul>
            </div>
    },
    {
        name: "Genesys",
        jobTitle: "Software Developer",
        setting: "Toronto, August 2023 - Present",
        description: 
            <div className='description'>
                
            </div>
        ,
        rolesText: "",
        roles: 
            <div className='roles'>
            </div>
    },
]