import { useState } from 'react'
import '../styles/pages/Experience.css'
import { companies } from '../content/Experience';
import { ExperienceTimeline } from '../components/ExperienceTimeline';

export function Experience() {
    const [currentIdx, setCurrentIdx] = useState(0);

    return (
        <div className="content">
            <h1 className="title">Experience</h1>

            <div id="experienceContent">
                <ExperienceTimeline setCurrentIdx={setCurrentIdx} />
                
                {
                    companies.map((company, idx) => {
                        return (
                            idx == currentIdx ?     
                            <div key={idx} className='info'>
                                <div className='name'>
                                    {company.name}
                                </div>
                                <div className='jobTitle'>
                                    {company.jobTitle}
                                </div>
                                <div className='setting'>
                                    {company.setting}
                                </div>
                                
                                {company.description}

                                <div className='rolesText'>
                                    {company.rolesText}
                                </div>

                                {company.roles}
                            </div> :
                            null
                        );
                    })
                }
            </div>
        </div>
    )
}