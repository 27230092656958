export const projects = [
    {
        name: "Website Portfolio",
        image: <img src={require('./ProjectPics/jasontedjo.png')} alt="Website Portfolio Image" className="projectPic"></img>,
        description: 
            <div className='description'>
                My own personal portfolio, created using <span className="highlight">React</span>. I hope for this to be a place where I can showcase my projects and interest, as well as my work history and experience. Stay tune for more updates!
            </div>,
        skills: [
            <a key={'react'} className='listItem' href="https://react.dev/" target="_blank" rel="noopener noreferrer">React</a>
        ],
        links: [
            <a key={'jasontedjo.com'} className='listItem' href="https://jasontedjo.com" target="_blank" rel="noopener noreferrer">jasontedjo.com</a>
        ]
    },
    {
        name: "CodeHunt",
        image: <img src={require('./ProjectPics/codehunt.png')} alt="CodeHunt Image" className="projectPic"></img>,
        description: 
            <div className='description'>
                CodeHunt is made to help Codeforces and UVa Online Judge users filter their problems with convenience and ease. You can also compare yourself with friends and rivals to motivate your practice and growth.
            </div>,
        skills: [
            <a key={'angular'} className='listItem' href="https://angular.io/" target="_blank" rel="noopener noreferrer">Angular</a>
        ],
        links: [
            <a key={'codehunt.cc'} className='listItem' href="https://codehunt.cc" target="_blank" rel="noopener noreferrer">codehunt.cc</a>,
            <a key={'codeforces'} className='listItem' href="https://codeforces.com/blog/entry/81053" target="_blank" rel="noopener noreferrer">Codeforces Blog</a>
        ]
    }
]