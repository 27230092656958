import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlinePhone } from 'react-icons/ai';
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineWorkOutline } from 'react-icons/md';
import { LiaPencilRulerSolid } from 'react-icons/lia';

export function IconNavigator(props: { pageOrder: any; }) {
    const navigate = useNavigate();
    const location = useLocation();

    const pageOrder = props.pageOrder;
    const noOfPages = pageOrder.length;

    function findInArray(s: string, arr: string[]) {
        for (let i = 0; i < arr.length; i++) {
          if (s === arr[i]) {
            return i;
          }
        }
        return -1;
      }

    const arrivalPage = findInArray(location.pathname, pageOrder);
    const [currentPage, setCurrentPage] = useState(arrivalPage);
    const [currentIconPivot, setCurrentIconPivot] = useState(arrivalPage);
    const [animationOn, setAnimation] = useState(0);

    useEffect(() => {
        navigate(pageOrder[currentPage])
    }, [])

    function goLeftPage() {
        setCurrentIconPivot(currentPage);

        let newPage = currentPage - 1;
        if (newPage < 0) {
        newPage += noOfPages;
        }
        setCurrentPage(newPage)
        navigate(pageOrder[newPage]);

        setAnimation(-1);
    }

    function goRightPage() {
        setCurrentIconPivot(currentPage);

        let newPage = currentPage + 1;
        if (newPage === noOfPages) {
            newPage = 0;
        }
        setCurrentPage(newPage)
        navigate(pageOrder[newPage]);

        setAnimation(1);
    }

    function iconClicked(idx: number) {
        if (idx === 1) goRightPage();
        if (idx === noOfPages - 1) goLeftPage();
    }

    const wheelAnimationEnded = () => {
        setAnimation(0);
        setCurrentIconPivot(currentPage);
    }

    // Have a circle with radius r centred at (0, 0). Get the coordinates of degree d.
    function circleCoor(r: number, d: number) {
        const rad = d / 180 * Math.PI;

        if (d <= 90) {
            return {
                x: r * Math.cos(rad),
                y: r * Math.sin(rad)
            }
        } else if (d <= 180) {
            return {
                x: r * Math.cos(rad),
                y: r * Math.sin(rad)
            }
        } else if (d <= 270) {
            return {
                x: r * Math.cos(rad),
                y: r * Math.sin(rad)
            }
        } else {
            return {
                x: r * Math.cos(rad),
                y: r * Math.sin(rad)
            }
        }
    }

    // Coordinates of center of circle path
    const cTop = 200 - 20;
    const cLeft = 265;
    const mainDotPos = {
        top: (cTop - circleCoor(120, 90).y),
        left: (cLeft + circleCoor(120, 90).x)
    }
    const leftDotPos = {
        top: (cTop - circleCoor(120, 160).y),
        left: (cLeft + circleCoor(120, 160).x)
    }
    const rightDotPos = {
        top: (cTop - circleCoor(120, 20).y),
        left: (cLeft + circleCoor(120, 20).x)
    }
    const bottomDotPos = {
        top: (cTop - circleCoor(120, 270).y),
        left: (cLeft + circleCoor(120, 270).x)
    }
    const animationTime = '1500ms'

    const iconStyles = [
        {
            still: {
                top: mainDotPos.top + 'px',
                left: mainDotPos.left + 'px',

                height: '100px',
                width: '100px',
                transform:'translate(-50%,-50%) rotate(0deg)'
            },
            goLeft: {
                top: mainDotPos.top + 'px',
                left: mainDotPos.left + 'px',

                offsetPath: `path("M0,0 A120,120 0 0,1 ${rightDotPos.left - mainDotPos.left},${rightDotPos.top - mainDotPos.top}")`,
                animation: `moveMain ${animationTime} ease-in-out`
            },
            goRight: {
                top: mainDotPos.top + 'px',
                left: mainDotPos.left + 'px',

                transform:'scale(-1,-1)',
                offsetPath: `path("M0,0 A120,120 0 0,0 ${leftDotPos.left - mainDotPos.left},${leftDotPos.top - mainDotPos.top}")`,
                animation: `moveMain ${animationTime} ease-in-out`
            },
            icon: {
                height: '70px',
                width: '70px',
            },
            goLeftIcon: {
                animation: `moveMainIcon ${animationTime} ease-in-out`
            },
            goRightIcon: {
                animation: `moveMainIcon ${animationTime} ease-in-out`
            },
        },
        {
            still: {
                top: rightDotPos.top + 'px',
                left: rightDotPos.left + 'px',

                height: '70px',
                width: '70px',
                transform:'translate(-50%,-50%) rotate(70deg)',
                
            },
            goLeft: {
                top: rightDotPos.top + 'px',
                left: rightDotPos.left + 'px',

                offsetPath: `path("M0,0 A120,120 0 0,1 ${bottomDotPos.left - rightDotPos.left},${bottomDotPos.top - rightDotPos.top}")`,
                animation: `moveFade ${animationTime} ease-in-out`
            },
            goRight: {
                top: rightDotPos.top + 'px',
                left: rightDotPos.left + 'px',
                
                transform:'scale(-1,-1)',
                offsetPath: `path("M0,0 A120,120 0 0,0 ${mainDotPos.left - rightDotPos.left},${mainDotPos.top - rightDotPos.top}")`,
                animation: `moveGrowToMain ${animationTime} ease-in-out`
            },
            icon: {
                height: '49px',
                width: '49px',
            },
            goLeftIcon: {
                animation: `moveFadeIcon ${animationTime} ease-in-out`
            },
            goRightIcon: {
                animation: `moveGrowToMainIcon ${animationTime} ease-in-out`
            },
        },
        {
            still: {
                top: bottomDotPos.top + 'px',
                left: bottomDotPos.left + 'px',

                height: '60px',
                width: '60px',
                transform:'translate(-50%,-50%) rotate(180deg)'
            },
            goLeft: {
                top: bottomDotPos.top + 'px',
                left: bottomDotPos.left + 'px',

                offsetPath: `path("M0,0 A120,120 0 0,1 ${leftDotPos.left - bottomDotPos.left},${leftDotPos.top - bottomDotPos.top}")`,
                animation: `moveGrowToSide ${animationTime} ease-in-out`
            },
            goRight: {
                top: bottomDotPos.top + 'px',
                left: bottomDotPos.left + 'px',

                transform:'scale(-1,-1)',
                offsetPath: `path("M0,0 A120,120 0 0,0 ${rightDotPos.left - bottomDotPos.left},${rightDotPos.top - bottomDotPos.top}")`,
                animation: `moveGrowToSide ${animationTime} ease-in-out`
            },
            icon: {
                height: '42px',
                width: '42px',
            },
            goLeftIcon: {
                animation: `moveGrowToSideIcon ${animationTime} ease-in-out`
            },
            goRightIcon: {
                animation: `moveGrowToSideIcon ${animationTime} ease-in-out`
            },
        },
        {
            still: {
                top: leftDotPos.top + 'px',
                left: leftDotPos.left + 'px',

                height: '70px',
                width: '70px',
                transform:'translate(-50%,-50%) rotate(290deg)'
            },
            goLeft: {
                top: leftDotPos.top + 'px',
                left: leftDotPos.left + 'px',

                offsetPath: `path("M0,0 A120,120 0 0,1 ${mainDotPos.left - leftDotPos.left},${mainDotPos.top - leftDotPos.top}")`,
                animation: `moveGrowToMain ${animationTime} ease-in-out`
            },
            goRight: {
                top: leftDotPos.top + 'px',
                left: leftDotPos.left + 'px',

                transform:'scale(-1,-1)',
                offsetPath: `path("M0,0 A120,120 0 0,0 ${bottomDotPos.left - leftDotPos.left},${bottomDotPos.top - leftDotPos.top}")`,
                animation: `moveFade ${animationTime} ease-in-out`
            },
            icon: {
                height: '49px',
                width: '49px',
            },
            goLeftIcon: {
                animation: `moveGrowToMainIcon ${animationTime} ease-in-out`
            },
            goRightIcon: {
                animation: `moveFadeIcon ${animationTime} ease-in-out`
            },
        }
    ]

    function displayIcon(idx: number, style: any) {
        if (idx === 0) {
            return (
                <AiOutlineHome 
                            style={animationOn === 0 ? style.icon : 
                                animationOn === 1 ? style.goRightIcon : style.goLeftIcon} className="icons"/>
            )
        } else if (idx === 1) {
            return (
                <MdOutlineWorkOutline
                            style={animationOn === 0 ? style.icon : 
                                animationOn === 1 ? style.goRightIcon : style.goLeftIcon} className="icons"/>
            )
        } else if (idx === 2) {
            return (
                <LiaPencilRulerSolid 
                            style={animationOn === 0 ? style.icon : 
                                animationOn === 1 ? style.goRightIcon : style.goLeftIcon} className="icons"/>
            )
        } else if (idx === 3) {
            return (
                <AiOutlinePhone 
                            style={animationOn === 0 ? style.icon : 
                                animationOn === 1 ? style.goRightIcon : style.goLeftIcon} className="icons"/>
            )
        }
    }

    function IconDisplay() {
        return (
        <div className='IconDisplay'>
            {
                iconStyles.map((style: any, idx: number) =>
                    <span 
                    style={animationOn === 0 ? style.still :
                        animationOn === 1 ? style.goRight : style.goLeft}
                    key={idx}
                    className={'dot'}
                    onClick={() => iconClicked(idx)}
                    onAnimationEnd={wheelAnimationEnded}>
                        {displayIcon((currentIconPivot + idx) % noOfPages, style)}
                    </span>)
            }
        </div>
        )
    }

    return (
        <div className="IconNavigator">
            <MdArrowBackIosNew className='arrows' onClick={goLeftPage}/>

            <IconDisplay />

            <MdArrowForwardIos className='arrows' onClick={goRightPage}/>
        </div>
    );
}