import { useNavigate } from 'react-router-dom';
import '../styles/pages/Mobile.css'

export function Mobile(props: any) {
    const navigate = useNavigate();

    const setIsMobile = props.setIsMobile;

    function continueToDesktop() {
        setIsMobile(false);
        navigate('/');
    }

    return (
        <div className="mobileContent">
            <h1 className="mobileTitle">Sorry...</h1>

            <p className="mobileText">The mobile site is a work in progress.</p>

            <div onClick={continueToDesktop} className='continueButton'>
                Continue anyways
            </div>
        </div>
    )
}