import { useState } from 'react'
import '../styles/ExperienceTimeline.css'
import { Aven, Genesys, Huawei, ThomsonReuters, UntetherAI, Wish } from '../content/Logos/logos';

const timelineStyles = [
    {
        top: '0%',
        zIndex: '100',
        transform: 'rotate(135deg)',
    },
    {
        top: '15%',
        left: '29.5%',
        transform: 'rotate(30deg)',
        zIndex: '99'
    },

    {
        top: '17%',
        left: '58%',
        transform: 'rotate(-45deg)',
        zIndex: '100'
    },
    {
        top: '34%',
        left: '29.5%',
        transform: 'rotate(150deg)',
        zIndex: '99'
    },

    {
        top: '34%',
        zIndex: '100',
        transform: 'rotate(45deg)',
    },
    {
        top: '49%',
        left: '29.5%',
        transform: 'rotate(30deg)',
        zIndex: '99'
    },

    {
        top: '51%',
        left: '58%',
        transform: 'rotate(-45deg)',
        zIndex: '100'
    },
    {
        top: '68%',
        left: '29.5%',
        transform: 'rotate(150deg)',
        zIndex: '99'
    },

    {
        top: '68%',
        zIndex: '100',
        transform: 'rotate(45deg)',
    },
    {
        top: '83%',
        left: '29.5%',
        transform: 'rotate(30deg)',
        zIndex: '99'
    },

    {
        top: '85%',
        left: '58%',
        transform: 'rotate(-45deg)',
        zIndex: '100'
    },
]

const timelinePictureStyles = [
    {
        top: '2.5%',
        left: '7.5%',
        zIndex: 105,
    },
    {
        top: '20%',
        left: '65.5%',
        zIndex: 105,
    },
    {
        top: '37%',
        left: '7.5%',
        zIndex: 105,
    },
    {
        top: '54%',
        left: '65.5%',
        zIndex: 105,
    },
    {
        top: '70.5%',
        left: '7.5%',
        zIndex: 105,
    },
    {
        top: '88%',
        left: '65%',
        zIndex: 105,
    },
]

const logos = [
    <Huawei />,
    <ThomsonReuters />,
    <UntetherAI />,
    <Wish />,
    <Aven />,
    <Genesys />
]

export function ExperienceTimeline(props: any) {
    const [target, setTarget] = useState(0);
    const [current, setCurrent] = useState(0);
    const setParentShowIdx = props.setCurrentIdx;

    function setCompany(idx: number) {
        setParentShowIdx(idx / 2);
        setTarget(idx);
    }

    function completedNext() {
        setCurrent(current + 1)
    }

    function completedBack() {
        setCurrent(current - 1)
    }

    return (
        <div id="experienceTimeline">
            {
                [
                    timelineStyles.map((timelineStyle, idx) =>
                        idx % 2 == 0 ? (
                            (target > current && current + 1 === idx) ?
                                <div key={idx} className='circleBarAnimatedNext' 
                                style={timelineStyle}
                                    onClick={() => setTarget(idx)}
                                    onAnimationEnd={completedNext} /> :
                            (target < current && current === idx) ?
                                <div key={idx} className='circleBarAnimatedBack' 
                                style={timelineStyle}    
                                onClick={() => setTarget(idx)}
                                    onAnimationEnd={completedBack} /> :
                            (current >= idx) ?
                                <div key={idx} className='circleBarCompleted' style={timelineStyle} onClick={() => setCompany(idx)} /> :
                                <div key={idx} className='circleBarIncomplete'  style={timelineStyle} onClick={() => setCompany(idx)} />
                        ) : (
                            (target > current && current + 1 === idx) ?
                                <div key={idx} className='bridgeBarAnimatedNext' style={timelineStyle} onAnimationEnd={completedNext} /> :
                            (target < current && current === idx) ?
                                <div key={idx} className='bridgeBarAnimatedBack' style={timelineStyle} onAnimationEnd={completedBack} /> :
                            (current >= idx) ?
                                <div key={idx} className='bridgeBarCompleted' style={timelineStyle}/> :
                                <div key={idx} className='bridgeBarIncomplete' style={timelineStyle} />
                        )
                    ),
                    timelinePictureStyles.map((timelinePictureStyle, idx) => 
                        {
                            const timelineIdx = idx * 2;
                            return (target > current && current + 1 === timelineIdx) ?
                                <div key={idx} className='timelineLogoAnimatedNext' style={timelinePictureStyle} onClick={() => setCompany(timelineIdx)}>
                                    {logos[idx]}
                                </div> :
                            (target < current && current === timelineIdx) ?
                                <div key={idx} className='timelineLogoAnimatedBack' style={timelinePictureStyle} onClick={() => setCompany(timelineIdx)}>
                                    {logos[idx]}
                                </div> :
                            (current >= timelineIdx) ?
                                <div key={idx} className='timelineLogoCompleted' style={timelinePictureStyle} onClick={() => setCompany(timelineIdx)}>
                                    {logos[idx]}
                                </div> :
                                <div key={idx} className='timelineLogoIncomplete' style={timelinePictureStyle} onClick={() => setCompany(timelineIdx)}>
                                    {logos[idx]}
                                </div>
                        }
                    )
                    
                ]
            }
        </div>
    )
} 