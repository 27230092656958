import './../styles/AppHeader.css';

function AppHeader() {
  return (
    <div className='header'>
      <h2 className='siteTitle'>
        Jason Tedjosoesilo
      </h2>
    </div>
  );
}

export default AppHeader;
