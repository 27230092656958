export const Huawei = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./Huawei.png')}  alt="HuaweiLogo" width={'30%'}/>
}

export const ThomsonReuters = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./ThomsonReuters.png')}  alt="ThomsonReutersLogo" width={'30%'}/>
}

export const UntetherAI = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./UntetherAI.png')}  alt="UntetherAILogo" width={'30%'}/>
}

export const Wish = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./Wish.png')}  alt="WishLogo" width={'30%'}/>
}

export const Aven = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./Aven.png')}  alt="AvenLogo" width={'30%'}/>
}

export const Genesys = (props: any) => {
    const size = props.size ?? 24;

    return <img src={require('./Genesys.png')}  alt="GenesysLogo" width={'30%'}/>
}
