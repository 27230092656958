import { useState } from 'react';
import '../styles/pages/Contact.css'
import { GMail, GitHub, LinkedIn } from '../content/svg'

export function Contact() {
    const [changed, setChanged] = useState([
        0, 0, 0
    ]);
    
    function changeColor(idx: number) {
        if (changed[idx] == 0) changed[idx] = 1
        setChanged([...changed]);
    }
    const animationTime = '2000ms'

    const styles =
        {
            icon: {
                fill: '#c2cff7'
            },
            growing: {
                animation: `changeIconColor ${animationTime} ease-in-out`
            },
            done: {
                fill: '#60f6d5'
            },

            text: {
                opacity: 0,
                cursor: 'default'
            },
            appearing: {
                animation: `changeText ${animationTime} ease-in-out`
            },
            appears: {
                opacity: 1
            },
        }

        const animationEnded = (idx: number) => {
            changed[idx] = 2;
            setChanged([...changed]);
        }

        const contacts = [
            {
                logo: <GitHub size={50} />,
                text: "github.com/jt3698",
                link: "https://github.com/jt3698"
            },
            {
                logo: <LinkedIn size={50} />,
                text: "in/jasontedjo",
                link: "https://www.linkedin.com/in/jasontedjo/"
            },
            {
                logo: <GMail size={50} />,
                text: "jasontedjosoesilo@gmail.com",
                link: "mailto:jasontedjosoesilo@gmail.com"
            },
        ]

    return (
        <div className="content">
            <h1 className="title">Contact</h1>

            <div id="contactContent">
            
                <div className='logos'>
                    {
                        contacts.map((contact, idx) => 
                        <div className='logo'
                            key={idx}
                            style={
                                changed[idx] === 0 ? styles.icon :
                                changed[idx] === 1 ? styles.growing : styles.done}
                                
                            onAnimationEnd={() => animationEnded(idx)}>
                                    
                                <a href={contact.link} target="_blank" rel="noopener noreferrer" onMouseEnter={() => changeColor(idx)} >
                                    {contact.logo}
                                </a>

                                <p style={
                                    changed[idx] === 0 ? styles.text :
                                    changed[idx] === 1 ? styles.appearing : styles.appears}>{contact.text}</p>
                        </div>)
                    }
                </div>

            </div>
            
        </div>
    )
}