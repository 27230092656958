import { MdOutlineCoPresent } from 'react-icons/md'
import { SiCodeforces } from 'react-icons/si'
import '../styles/pages/Projects.css'
import { useNavigate } from 'react-router-dom';
import { projects } from '../content/Projects';

export function Projects() {
    const navigate = useNavigate();

    const icons = [
        <MdOutlineCoPresent className="project-icon"/>,
        <SiCodeforces className="project-icon"/>
        // AiOutlinePhones
    ]

    function Project(props: any) {
        const idx = props.idx;
        
        if (idx != undefined) {
            return <span 
                        className="project" 
                        style={{cursor: 'pointer'}}
                        onClick={() => navigate('/projects/' + idx)}>
                    
                    <div className="card-wrapper">
                        <div className="card-top">
                            {icons[idx]}
                        </div>
                        <div className="card-bottom">
                            <span className="top-text">{projects[idx].name}</span>
                        </div>
                    </div>
                </span>
        } else {
            return <span className="project">
            </span>
        }
    }

    return (
        <div className="content">
            <h1 className="title">Projects</h1>

            <div id='projectsBox'>
                <div className="projectRow">
                    <div className='projectBox'>
                        <Project idx={0}/>
                    </div>
                    <div className='projectBox'>
                        <Project idx={1}/>
                    </div>
                    <div className='projectBox'>
                        <Project />
                    </div>
                    <div className='projectBox'>
                        <Project />
                    </div>
                </div>

                <div className="projectRow">
                    <div className='projectBox'>
                        <Project />
                    </div>
                    <div className='projectBox'>
                        <Project />
                    </div>
                    <div className='projectBox'>
                        <Project />
                    </div>
                    <div className='projectBox'>
                        <Project />
                    </div>
                </div>
            </div>
        </div>
    )
}