import { Routes, Route, useLocation } from 'react-router-dom';
import { AboutMe } from './pages/AboutMe';
import { Experience } from './pages/Experience';
import { Projects } from './pages/Projects';
import { Contact } from './pages/Contact';
import AppHeader from './components/AppHeader';
import './styles/App.css'
import './styles/IconNavigator.css'
import { IconNavigator } from './components/IconNavigator';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import { mobileCheck } from './mobileCheck';
import { Mobile } from './pages/Mobile';
import { projects } from './content/Projects';
import { IndividualProject } from './pages/IndividualProject';

function App() {
  let location = useLocation();
  const [isMobile, setIsMobile] = useState(mobileCheck());
  
  const pageOrder = [
    '/',
    '/experience',
    '/projects',
    '/contact'
  ]

  function isInArray(s: string, arr: string[]) {
    for (let cur of arr) {
      if (s === cur) {
        return true;
      }
    }
    return false;
  }

  const isMainPath = isInArray(location.pathname, pageOrder);

  const nodeRef = useRef(null)
  
  return (
    <div className='App'>
      {
        isMobile ? 
          <Mobile setIsMobile={setIsMobile} />
        : [
        <AppHeader key={'appheader'}></AppHeader>,

        <TransitionGroup key={'routes'} nodeRef={nodeRef} component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={1500} exit={false}>

          <Routes location={location}>
            <Route
              path="/"
              element={<AboutMe/>} />
            <Route
              path="/experience"
              element={<Experience/>} />
            <Route
              path="/projects"
              element={<Projects/>} />
            <Route
              path="/contact"
              element={<Contact/>} />

            {
              projects.map((project, idx) => 
              <Route
                key={idx}
                path={"/projects/" + idx}
                element={<IndividualProject project={project}/>} />)
            } 
          </Routes>
        
        </CSSTransition>
        </TransitionGroup>,
        
          isMainPath && <IconNavigator key={'iconNavigator'} pageOrder={pageOrder}/>
        ]
    }
    </div>
  )
}

export default App;
