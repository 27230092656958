import { useNavigate } from "react-router-dom";
import '../styles/pages/IndividualProject.css'

export function IndividualProject(props: any) {
    const navigate = useNavigate();
    const project = props.project;

    return (
        <>
            <h1 className="back" style={{cursor: 'pointer'}} onClick={() => navigate('/projects')}>Back</h1>
            <div className="content">
                
                <div className="info">
                    <div className="projectInfo">
                        {project.image}

                        <div>   
                            <div className="name">{project.name}</div>

                            {project.description}

                            <div className="listedItems">
                                <div className="itemTitle">Links</div>
                                {project.links}
                            </div>

                            <div className="listedItems">
                                <div className="itemTitle">Skills</div>
                                {project.skills}
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </>
    )
}