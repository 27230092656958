import '../styles/pages/AboutMe.css'

export function AboutMe() {
    return (
        <div className="content">
            <h1 className="title">About Me</h1>
            <p id="aboutMeContent">
            <span className='highlight'>Software Engineer</span> with 2+ years of experience. 
            Worked extensively on full-stack development through internships and personal endeavors. 
            Involved in several AI projects, from developing machine learning models for recommendation systems to middleware technologies. 
            Dedicated to excelling as part of a diverse, fast-paced, and communication-driven team in bringing innovative solutions to complex problems.
            </p>
        </div>
    )
}